.algorithms-section {
    width: 1200px;
}

.about-text {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.algorithms {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 1500px;
}

.algorithm-margin {
    margin: 30px;
}

.algorithm {
    max-width: 300px;
    height: 170px;
    border-radius: 10px;
    border: 3px solid black;
}

.algorithm-name, .algorithm-description {
    text-align: center;
    color: black;
}

.algorithm-name {
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 16px;
}

.algorithm-description {
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
}

.github-icon {
    margin: auto;
}