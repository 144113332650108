@media (max-width: 1200px) {
  .nav-menu-block {
    border-left: .5px black solid;
    border-right: none;
  }

  .nav-menu-block-first {
    border-left: none;
  }
}
@media (max-width: 750px) {
  .nav-menu-wide {
    height: 30px;
    width: 100%;
  }
  .nav-menu-block {
    font-size: 12.5px;
  }

  .section.home {
    flex-direction: column-reverse;
    align-items: center;
  }

  .home-info {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }

  .banner-buttons {
    margin: 0px;
    margin-bottom: 10px;
  }
  .job-title-text, .name-text, .pgp-text {
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
  }
  .name-text {
    margin-top: 0px;
  }
  .job-title-text {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .photo {
    margin: 50px 0px 25px 0px;
  }
  .project {
    margin-top: 30px;
  }

  .contact-body {
    flex-direction: column;
    align-items: start;
    margin-left: 35px;
  }

  .contact-info, .contact-form {
    padding: 0px;
    padding-top: 15px;
  }

  .contact-form-fields {
    margin-bottom: 20px;
  }
}

@media (max-width: 540px) {
  .skill-icon {
    width: 80px;
    height: 80px;
  }
  .skill {
    max-width: 80px;
  }
  .skill-name {
    font-size: 15px;
  }
}
