:root {
  --section1: rgba(58, 134, 255, .65);
  --section2: rgba(131, 56, 236, .65);
  --section3: rgba(28, 209, 0, 0.6);
  --section4: rgba(58, 134, 255, .65);
  --section5: rgba(131, 56, 236, .65);
  --section6: rgba(28, 209, 0, 0.6);
  --project: rgba(131, 56, 236, .65);
  --algorithm: rgba(58, 134, 255, .65);

  --transition1: linear-gradient(var(--section1), var(--section2));
  --transition2: linear-gradient(var(--section2), var(--section3));
  --transition3: linear-gradient(var(--section3), var(--section4));
  --transition4: linear-gradient(var(--section4), var(--section5));
  --transition5: linear-gradient(var(--section5), var(--section6));
}

.section-transition {
  height: 50px;
  width: 100%;
}

.project {
  background-color: var(--project)
}

.algorithm {
  background-color: var(--algorithm);
}

.home-wide-section {
  background-color: var(--section1);
}

.about-wide-section {
  background-color: var(--section2);
}

.skills-wide-section {
  background-color: var(--section3);
}

.projects-wide-section {
  background-color: var(--section4);
}

.algorithms-wide-section {
  background-color: var(--section5)
}

.contact-wide-section {
  background-color: var(--section6);
}

.section-transition-1 {
  background: var(--transition1);
}
.section-transition-2 {
  background: var(--transition2);
}
.section-transition-3 {
  background: var(--transition3);
}
.section-transition-4 {
  background: var(--transition4);
}
.section-transition-5 {
  background: var(--transition5);
}
.section-transition-6 {
  background: var(--transition6);
}