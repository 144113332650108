body {
  background-color: lightgray;
}

h1, p {
  margin: 0px;
}

h2 {
  font-size: 40px;
  margin: 10px;
  margin-top: 10px;
  padding-top: 10px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.wide-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-wrapper {
  width: 100%;
  max-width: 1200px;
}

.title {
  text-align: center;
}

.section {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 100%;
  max-width: 1200px;
}
