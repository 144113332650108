.section.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content.skills {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.skill-icon {
    width: 140px;
    height: 140px;
}

.skill-name {
    text-align: center;
    margin: 4px;
    font-size: 20px;
}
