.nav-menu-wide {
  width: 100%;
  height: 40px;
  position: fixed;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  border-top: black .5px solid;
  border-bottom: black .5px solid;
}

.nav-menu {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-evenly;
}

.nav-menu-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 25px;
  font-weight: 500;
  border-right: black .5px solid;
}

.nav-menu-block-first {
  border-left: black .5px solid;
}

.nav-menu-block:hover {
  background-color: gray;
  color: white;
  animation: hoverAnimation .5s;
}
@keyframes hoverAnimation {
  from {background-color: lightgray;}
  to {background-color: gray;}
  from {color: gray}
  to {color: white}
}
