.subtitle {
  font-size: 35px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 5px;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1500px;
}

.project {
  max-width: 300px;
  width: 100%;
  margin: 30px;
  height: 400px;
  border-radius: 10px;
  border: 3px solid black;
}

.project-mobile {
  height: 750px;
}

.project-image {
  width: 280px;
  height: 225px;
  margin: 10px;
  border-radius: 5px;
}

.project-image-mobile {
  width: 280px;
  height: 575px;
  margin: 10px;
  border-radius: 5px;
}

.project-name {
  text-align: center;
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 16px;
}

.project-name-mobile {}

.project-tagline {
  text-align: center;
  font-size: 15px;
}

.project-tagline-mobile {}

.github-icon-container {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.github-icon-container-mobile {}

.github-icon {
  width: 25px;
  height: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
  margin: auto;
}
